<template>
  <el-dropdown
    ref="accountDropDown"
    trigger="click"
    placement="bottom-start"
    style="margin-left: 30px"
    @visible-change="getDropdownStatus"
  >
    <div class="account-wrapper">
      <div
        class="pr mx-10"
        :class="['unf', activePlat.name != 'f']"
      >
        <i
          v-if="activePlat.name != 'f'"
          class="el-icon-info"
        ></i>
        <span
          v-else
          class="define-f dp-flex justify-c align-item-c"
          >{{ activePlat.name }}</span
        >
        <!-- <p
          :class="[
            'f-circle',
            'pa',
            activeAdAccount && activeAdAccount.accountStatus == 1 ? 'bg-success' : 'bg-danger',
          ]"
        ></p> -->
      </div>
      <el-tooltip
        placement="bottom"
        effect="light"
      >
        <div slot="content">
          <span
            v-for="item in defaltAccountList"
            :key="item.id"
            :style="item.accountStatus !== 1 ? 'color:#f00;' : ''"
            >{{ item.displayName || item.name }}，</span
          >
          <!-- {{defaltAccountList.length?defaltAccountList.map(v=>v.name).join(','):activeAdAccount.name}} -->
        </div>
        <p
          class="ellipsis-1"
          style="line-height: initial"
        >
          {{ defaltAccountList.length ? defaltAccountList.map((v) => v.displayName || v.name).join(',') : '' }}
          <!-- giikin-{{activeAdAccount.id}} -->
        </p>
      </el-tooltip>
      <i class="el-icon-caret-bottom"></i>
    </div>
    <el-dropdown-menu slot="dropdown" v-loading="loadAccount">
      <div class="account-wrap" >
        <el-row>
          <el-col :span="24">
            <el-tabs
              v-model="activeName"
              style="width: 850px"
              @tab-click="selectLabel"
              
            >
              <!-- <el-tab-pane label="未分组"> </el-tab-pane> -->
              <!-- <el-tab-pane label="封禁账户"> </el-tab-pane> -->
              <el-tab-pane
                v-for="(num, numIndex) in loginListLabel"
                :key="numIndex"
                :label="num.name"
                :name="num.id"
              >
                <div
                  class="account-list-container pr"
                  :loading="loadingAccount"
                  v-show="num.list.length > 0"
                >
                  <!-- 刷新-搜索-来源 -->
                  <!-- <cus-header
                    @search="handleSearch"
                    @syncAccount="syncAccount"
                    :multiAccount="true"
                  ></cus-header> -->
                  <el-checkbox
                    :indeterminate="isIndeterminate"
                    v-model="checkAll"
                    @change="handleCheckAllChange"
                    >全选</el-checkbox
                  >
                  <el-checkbox-group
                    v-model="checkList"
                    class="account-list-wrapper"
                    @change="selectAccount"
                  >
                    <el-checkbox
                      :label="item.id"
                      v-for="(item,index) in num.list.filter((v) => v != null)"
                      :key="`${item.id}${num.id}${index}`"
                    >

                      <div class="account-list-item">
                        <div class="account-list-item-top">
                          <div class="icon-wrapper pr">
                            <i
                              v-if="activePlat.name != 'f'"
                              class="el-icon-info"
                            ></i>
                            <img
                              v-else
                              style="width: 15px; height: 15px"
                              src="https://app.sinoclick.com/static/media/fb.d03076a0.svg"
                            />
                            <p
                              :class="['small-circle', 'pa', item.accountStatus == 1 ? 'bg-success' : 'bg-danger']"
                            ></p>
                          </div>
                          <el-tooltip :content="item.displayName+'('+item.name+')'"  :disabled="getTextSize(item.displayName+'('+item.name+')',14).width<290"
                            ><span class="account-name">{{ item.displayName || item.name }}<span v-if="item.displayName">({{ item.name }})</span></span></el-tooltip
                          >
                        </div>
                        <div class="account-list-item-bottom">
                          <span class="account-id">ID:{{ item.id }}</span>
                          <el-tooltip :content="`当前账号在投放中/审核中广告数量：${item.adsRunningCount}`">
                            <span class="status-disabled num bg-success">{{ item.adsRunningCount }}</span>
                          </el-tooltip>
                          <el-tooltip content="禁用">
                            <span
                              v-if="item.accountStatus != 1"
                              class="status-disabled"
                              >禁用</span
                            >
                          </el-tooltip>
                          <el-tooltip content="额度低">
                            <span
                              v-if="item.spendCap && item.spendCap - item.amountSpent < 5"
                              class="status-disabled"
                              >额度低</span
                            >
                          </el-tooltip>
                          <span
                            class="current"
                            @click.stop="newWindowOpenAccount(item)"
                            >新窗口打开</span
                          >
                        </div>
                      </div>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <div
                  v-show="num.list.length == 0"
                  class="dp-flex justify-c align-item-c account-list-wrapper"
                  style="top: 70px; bottom: 0; left: 0; right: 0"
                >
                  <el-empty description="无匹配数据"></el-empty>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </div>
      <div class="multiAccountBtn">
        <el-button
          size="mini"
          @click="cancle"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="setMultiAccountList"
          >确定</el-button
        >
      </div>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import cusHeader from './cusHeader';
import { checkAccountStatus } from '@/api/common/account';
import { adaccouts, queryAdAccountLabelListByUserId, loginListGroup } from '@/api/common/user';
import {getTextSize} from '@/utils/utils'
export default {
  components: {
    cusHeader,
  },
  data() {
    return {
      // 当前选中的来源平台-便于后期平台切换时帐号前图标的转换
      activePlat: '',
      // 本地搜索
      selectNum: '',
      loadingAccount: false,
      keyword: '',
      defaltAccountList:[],
      loginListLabel: [],
      defaultProps: {
        children: 'thirdLoginUsers',
        label: 'name',
      },
      activeName: null, //选中选项卡的 name
      currentLabelAccountList:[],
      checkList:[],
      loadAccount:false,
    };
  },
  // watch:{
  //     selectNum:{
  //         handler(){
  //             console.log('aaaaaaaaaaaaaa');
  //             console.log(this.defalutNum);
  //             this.selectNumClick(this.defalutNum)
  //         },
  //         immediate:true

  //     }
  // },
  computed: {
    ...mapState('num', ['adAccountsLists', 'activeAdAccount', 'selectedNum','multiAccountList']),
    ...mapGetters('num', ['hasActiveAdAccountsLists', 'defNum', 'validNumLists']),
    adAccountsList() {
      console.log(this.adAccountsLists, this.selectedNum, 'this.adAccountsLists,this.selectedNum');
      let list = this.adAccountsLists.map((v) => {
        v.multiId = v.id;
        return v;
      });
      return list;
    },
    defalutNum: {
      get() {
        return this.selectNum || this.selectedNum || localStorage.getItem('selectedNum');
      },
      set(v) {
        this.selectNum = v;
        this.selectNumClick(this.defalutNum);
      },
    },
    checkAll:{
      get(){
        return this.currentLabelAccountList.map(k=>k.id).every((v) =>(this.checkList.includes(v)))
      },
      set(v) {

      }
    },
    isIndeterminate:{
      get() {
        let list = [];//已经被选择切存在当前标签下的广告账户
        this.currentLabelAccountList.forEach(item=>{
          if(this.checkList.includes(item.id)) list.push(item.id);
        }) 
        return (list.length > 0) && (list.length<this.currentLabelAccountList.length)
      },
      set(){

      }
    }
  },
  methods: {
    getTextSize(text,fontSize){
      return getTextSize(text,fontSize)
    },
    // 获取带标签的账户列表
    loginListGroup() {
      let params = { platform: 'facebook' };
      this.loadAccount = true
      return queryAdAccountLabelListByUserId().then(async (res) => {
        this.loadAccount = false;
        if (res.code == 0) {
          this.loginListLabel = res.data.map((data) => {
            data.id = data.id + ''
            data.list.map((v) => {
              v.multiId = data.id!='0'?data.id:'-2';
            });
            return { ...data };
          });
          this.activeName = (localStorage.getItem('multiAccountLabel')&& this.loginListLabel.findIndex(v => v.id == this.activeName) != -1)? localStorage.getItem('multiAccountLabel') : (this.loginListLabel[0].id);
          this.defaltAccountList = this.multiAccountList.length?this.multiAccountList:(this.loginListLabel.find(v=>v.id == this.activeName)?.list|| this.loginListLabel[0].list || []);
          this.checkList = this.defaltAccountList.map((v) => v.id);
          let index = this.loginListLabel.findIndex(v => v.id == this.activeName) != -1?this.loginListLabel.findIndex(v => v.id == this.activeName):0;
          // this.currentLabelAccountList = this.loginListLabel[index].list;
          this.selectLabel({...this.loginListLabel.find(v => v.id == this.activeName),index:index},false)
          this.$store.commit('initData/setAdAccountLabelList', this.loginListLabel);
        }
      });
    },
    // 点击切换标签
    selectLabel(v,flag){
      console.log(v,flag);
      // this.currentLable = v.name
      this.currentLabelAccountList = this.loginListLabel[v.index].list;
      if(flag){
        this.checkAll = true;
        this.checkList = this.currentLabelAccountList.map((v) => v.id)
      }
    },
    // 点击全选
    handleCheckAllChange(v) {
      if(v){
        this.checkList = this.currentLabelAccountList.map((v) => v.id)
      }else{
        this.checkList = []
      }
    },
    // 点击弹出账户弹窗
    getDropdownStatus(v){
      if(v){
        this.loginListGroup()
      }else{
        this.loginListLabel = []
      }
    },
    // 选择单个账户
    selectAccount(v){
      // if(v.length > 0 && v.length<this.currentLabelAccountList.length){
      //   this.isIndeterminate = true;
      // }else{
      //   this.isIndeterminate = false;
      // }
    },
    initActiveAdAccount(arr) {
      if (arr.length) {
        // 启用帐号中的第一个
        let eableAdAccounts = arr.filter((item) => item.accountStatus == 1);
        if (eableAdAccounts) {
          return {
            ...eableAdAccounts[0],
          };
        } else {
          // 禁用帐号中的第一个
          return {
            ...arr[0],
          };
        }
      }
    },
    // 点击切换个号
    selectNumClick(v) {
      if (!this.numListsMap[v].loadedAccount) {
        this.getAccountByNum(v);
      }
    },
    //
    handleNodeClick(data) {
      // console.log(data);
      if (data.status) {
        this.selectNumTap(data);
      }
    },
    selectNumTap(v) {
      this.defalutNum = v.id;
    },
    checkAccountStatus2(item, href) {
      this.$showLoading();
      let tipHtml = `<p style="font-weight: bold;font-size: 14px;">新窗口打开确认</p><p style="margin-top: 10px;">你选择的广告账号<strong>${item.name}</strong>状态是：<strong>禁用</strong> ，只有启用中的账号才能创建/编辑广告。确定在新窗口打开该账号吗？</p>`;
      return checkAccountStatus({ id: item.id }).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          let pass = res.data.check_pass;
          // 禁用时弹出切换确认框
          if (!pass) {
            this.$confirm(tipHtml, '', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              customClass: 'change-status-msg',
              type: 'warning',
              dangerouslyUseHTMLString: true,
            })
              .then(() => {
                window.open(href, '_blank');
                this.$refs.accountDropDown.visible = false;
              })
              .catch(async () => {
                this.$message({
                  type: 'info',
                  message: `已取消切换到帐号${item.name}!`,
                });
                // await this.getAdAccounts({ obj: this.activeAdAccount });
              });
          } else {
            window.open(href, '_blank');
            this.$refs.accountDropDown.visible = false;
          }
        }
      });
    },
    async newWindowOpenAccount(item) {
      let { href, route } = this.$router.resolve({
        path: '/ad/adManagement',
        query: { id: item.id },
      });
      await this.checkAccountStatus2(item, href);
    },
    // 本地搜索
    handleSearch(ipt) {
      this.isSearch = true;
      this.keyword = ipt;
    },
    // 点击确定
    setMultiAccountList() {
      if (!this.checkList.length) {
        this.$message({
          type: 'warning',
          message: '请先选择账号',
        });
        return false;
      }
      let list = [];//已经被选择切存在当前标签下的广告账户
      this.currentLabelAccountList.forEach(item=>{
        if(this.checkList.includes(item.id)) list.push(item);
      }) 
      this.defaltAccountList = list
      localStorage.setItem('multiAccountList', JSON.stringify(list));
      localStorage.setItem('multiAccountLabel', this.activeName);
      this.$store.commit('num/SETMULTIACCOUNTLIST', list);
      this.close();
    },
    // 关闭弹窗
    cancle() {
      this.defaltAccountList = this.defaltAccountList || [];
      this.close();
    },
    //关闭弹窗
    close(){
      this.$refs.accountDropDown.visible = false;
    },
    // 清除失效账户
    clearDisAccount() {
      console.log(this.checkList.filter((v) => v.accountStatus == 1));
      this.checkList = this.defaltAccountList.filter((v) => v.accountStatus == 1).map((v) => v.multiId);
    },
  },
  created() {
    this.$bus.$on('post-activePlat', (activePlat) => {
      this.activePlat = activePlat;
    });
    this.loginListGroup();
  },
};
</script>

<style lang="scss">
.account-wrap {
  display: flex;
  padding: 10px;
  .num-wrap {
    flex: 200px 0 0;
    padding: 20px;
    max-height: 500px;
    overflow-y: auto;
    .num-item {
      padding: 15px 0;
      font-size: 14px;
      color: #666;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &.active {
        color: #247ae6;
      }
    }
  }
}
.numTabs {
  .el-tabs--left .el-tabs__item.is-left {
    font-size: 12px;
  }
  .el-tabs__item {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.multiAccountBtn {
  padding: 10px 20px;
  text-align: right;
}
.account-wrapper {
  display: flex;
  align-items: center;
  width: 360px;
  height: 28px;
  font-size: 14px;
  color: #fff;
  background: #0c5dc2;
  cursor: pointer;

  i {
    margin: 0 10px;
  }

  i.el-icon-caret-bottom {
    margin-left: auto;
  }
}

.account-list-container {
  padding: 10px;
  flex: 1;
  overflow: hidden;
  .account-list-wrapper {
    width: 800px;
    height: 300px;
    min-height: 130px;
    overflow-y: auto;
    .el-checkbox {
      width: 360px;
    }
    .el-checkbox__label {
      // display: block;
      padding-left: 0;
    }
    .el-checkbox__input {
      vertical-align: top;
      margin-top: 20px;
    }
    .account-list-item {
      //   width: 50%;
      float: left;
      cursor: pointer;
      height: 55px;
      position: relative;
      transition: all 0.3s linear 0s;
      margin-left: 0 !important;
      padding-left: 0 !important;

      &:hover {
        background-color: rgb(245, 245, 247);
      }

      .account-list-item-top {
        display: flex;
        align-items: center;
        width: 100%;
        height: 35px;

        .icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          font-size: 20px;
          color: #247ae6;
        }

        .account-name {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          float: left;
          // max-width: 232px;
          font-size: 14px;
        }
      }
      .current {
        border-radius: 2px;
        border: 1px solid rgb(178, 208, 246);
        font-size: 10px;
        padding: 1px 8px;
        margin-left: 7px;
        color: rgb(36, 122, 230);
      }
      .account-list-item-bottom {
        display: flex;
        align-items: center;
        width: 100%;
        height: 20px;

        .account-id {
          font-size: 12px;
          color: rgb(185, 187, 190);
          opacity: 0.8;
          padding-left: 16px;
          margin-left: 20px;
        }
        .status-disabled {
          display: inline-block;
          margin-left: 0;
          padding: 1px 8px;
          font-size: 12px;
          color: rgb(255, 163, 158);
          border: 1px solid rgb(255, 163, 158);
          transform: scale(0.8);
          border-radius: 2px;
          margin: 0 5px;
          &.num {
            border: none;
            background: #c0c4cc;
            color: #fff;
            transform: scale(1);
          }
        }
      }
    }

    .active {
      background: rgb(238, 244, 255);
    }
  }
}

.f-circle,
.small-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.f-circle {
  top: -2px;
  right: -2px;
}
.small-circle {
  top: 8px;
  left: 20px;
}
.change-status-msg {
  .el-message-box__status {
    top: unset;
    transform: translateY(0);
  }
}
.define-f {
  width: 15px;
  height: 15px;
  color: #fff;
  font-size: 14px;
  border: 2px solid #fff;
  border-radius: 50%;
}
.unf {
  line-height: initial;
  i {
    margin: 0;
    font-size: 15px;
  }
}
</style>
