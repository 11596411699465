<template>
  <el-popover
    placement="bottom"
    width="600"
    trigger="click"
    popper-class="changeAccountPop"
  >
    <div class="changeAccount">
      <div class="title">
        <el-input
          placeholder="请输入广告系列名称/id"
          v-model="campaignId"
          class="input-with-select"
          size="mini"
          @keyup.enter.native="searchAccount"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchAccount"
          ></el-button>
        </el-input>
      </div>
      <div
        class="accountList"
        v-loading="loading"
      >
        <ul v-if="list.length">
          <el-collapse
            v-model="activeIds"
            @change="handleChange"
          >
            <el-collapse-item
              v-for="item in list"
              :key="item.actId"
              :name="item.actId"
            >
              <template slot="title">
                <div class="dp-flex justify-btw flex-1 mr-10">
                  <div
                    class="flex-1 w-0"
                    style="color: #999"
                  >
                    <div class="ellipsis-1">账户：{{ item.actName }}({{ item.actId }})</div>
                  </div>
                  <div>
                    <el-button
                      size="mini"
                      type="primary"
                      @click.stop="changeBtn(item)"
                      >切换</el-button
                    >
                  </div>
                </div>
              </template>

              <div>
                <div
                  v-for="campaignInfo in item.campaignInfoList"
                  :key="campaignInfo.campaignId"
                  class="ml-20 mr-20"
                >
                  {{ campaignInfo.campaignName }}
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </ul>
        <div
          v-else-if="!loading"
          class="empty"
        >
          暂无数据
        </div>
      </div>
    </div>

    <!-- 图标 -->
    <el-tooltip
      content="搜索广告系列快速切换账户"
      slot="reference"
    >
      <div class="changeIcon">
        <i
          class="el-icon-sort"
          style="transform: rotate(90deg)"
        ></i>
      </div>
    </el-tooltip>
  </el-popover>
</template>
<script>
import { switchActByKeyword } from '@/api/adManagement';
import { fnCSTToYmd } from '@/utils/utils';
import { mapState, mapGetters } from 'vuex';
export default {
  data() {
    return {
      campaignId: '',
      list: [],
      loading: false, //加载中
      activeIds: [],
    };
  },
  computed: {
    defaultDateRange() {
      let end = new Date(),
        start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * (24 * (30 - 1)));
      return [fnCSTToYmd(start, { tag: 1 }), fnCSTToYmd(end, { tag: 1 })];
    },
    ...mapState('num', ['adAccountsLists']),
  },
  methods: {
    clearData() {
      this.campaignId = '';
      this.list = [];
    },
    searchAccount() {
      if (!this.campaignId) return false;
      this.loading = true;
      let params = {
        keyword: this.campaignId,
        platform: 'facebook',
      };
      switchActByKeyword({ ...params }, this)
        .then((res) => {
          this.list = res.data || [];
          this.activeIds = this.list.map((item) => item.actId);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async selectNum(item) {
      await this.$store.dispatch('num/setSelectedNum', {
        selectedNum: item.thirdUserId,
      });
      console.log(JSON.parse(localStorage.getItem('activeAdAccount')).id);
      this.$store.commit('initData/setCampaignMsg', {
        loadTable: true,
        campaignId: this.campaignId,
        changeAccount: item.actId != JSON.parse(localStorage.getItem('activeAdAccount')).id,
      });
      localStorage.removeItem('activeAdAccount');
      sessionStorage.removeItem('activeAdAccount');
      this.$bus.$emit('trigger-getAdAccounts', { obj: { id: item.actId } });

      //   记录筛选的系列id,加载列表带上参数
    },
    changeBtn(item) {
      this.$confirm('确定要切换到该账号吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.selectNum(item);
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.empty {
  text-align: center;
  line-height: 2;
  color: #999;
}
</style>
<style lang="scss">
.changeIcon {
  i {
    font-size: 16px;
    margin-left: 10px;
    top: 5px;
  }
}
.changeAccountPop {
  max-height: 80vh;
  min-height: 300px;
  overflow-y: scroll;
}
</style>
