<template>
  <div class="cus-header-ly dp-flex justify-btw mb-10">
    <nav-menu :data="navData" @selectedNav="selectedNav">
      <template v-slot:content="{ item }">
        <div v-if="item.name == 'f'">
          <img
            style="width:20px;height:20px;"
            src="https://app.sinoclick.com/static/media/fb.d03076a0.svg"
          />
        </div>
      </template>
    </nav-menu>
    <div class="dp-flex align-item-c" style="margin-right:30px;">
      <div v-if="!isAdmanagement" class="mr-10">
        <p class="no-wrap" @click="handleSyncAdAccount">
          <i
            class="el-icon-refresh-left f-16 color-theme cursor-pointer mr-10"
          ></i
          ><span class="title-top">同步账号状态</span>
        </p>
        <p class="no-wrap small-title">
          {{ adAccountsAllDatas.version_time }}
        </p>
      </div>
      <el-input
        size="small"
        placeholder="输入广告账户名称/ID搜索"
        clearable
        v-model="searchIpt"
        @input="handleSearch(searchIpt)"
      >
        <!-- <i
          @click="handleSearch(searchIpt)"
          slot="suffix"
          class="el-icon-search"
        ></i> -->
      </el-input>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import navMenu from "@/views/components/navMenu";
import { synBM } from "@/api/adAssets";
export default {
  props:{
    multiAccount:{
      type:Boolean,
      default:false
    }
  },
  components: {
    navMenu
  },
  data() {
    return {
      activePlatId: "",
      version_time: "",
      // 导航
      navData: {
        navMenu: [
          // { id: "1", name: "全部" },
          { id: "2", name: "f" }
        ],
        activeNavIndex: "2",
        bgc: "",
        mode: "horizontal",
        customClass: ""
      },
      // 搜索
      searchIpt: ""
    };
  },
  computed: {
    ...mapState("num", ["adAccountsAllDatas"]),
    isAdmanagement() {
      console.log(this.$route.path);
      return this.$route.path == '/ad/multiAccountManageIndex';
    },
  },
  methods: {
    selectedNav(key) {
      this.activePlatId = key;
    },
    // 广告同步
    async synBM() {
      this.$showLoading();
      await this.$store.dispatch("num/setIsSync", { isSync: false });
      synBM({ type: "account" }).then(async res => {
        this.$hideLoading();
        if (res.code == 200) {
          this.$message.success(res.comment);
          this.searchIpt = "";
          this.$bus.$emit("trigger-getAdAccounts", {
            obj: this.$store.state.num.activeAdAccount
          });
          await this.$store.dispatch("num/setIsSync", { isSync: true });
        }
      });
    },
    // 同步广告帐号
    handleSyncAdAccount() {
      if(this.multiAccount){
        this.$emit('syncAccount')
      }else{
        this.synBM();
      }
    },
    // 搜索
    handleSearch(ipt) {
      // 本地搜索
      this.$emit("search", ipt);
    }
  },
  created() {
    this.$bus.$on("trigger-synBM", () => {
      this.synBM();
    });
  },
  mounted() {
    this.activePlatId = this.navData.activeNavIndex;
  },
  watch: {
    activePlatId: {
      handler(val) {
        // console.log("activePlatId", val);
        let obj = this.navData.navMenu.find(item => item.id == val);
        this.$bus.$emit("post-activePlat", obj);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.cus-header-ly {
  .title-top {
    color: $theme-color;
  }
  .small-title {
    @extend .f-12;
    color: rgb(156, 158, 164);
  }
  .el-input__suffix {
    margin-right: 5px;
    @extend .dp-flex;
    @extend .align-item-c;
  }
  .no-wrap {
    white-space: nowrap;
  }
}
</style>